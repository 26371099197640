import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';

import moment from 'moment';
import { getImageUrl } from '~/util/Functions';
import { IMG_BAG_CARD } from '~/constants/Images';
import Image from '~/components/Image';
import { CardContainer, CardDescription, CardTitleDescription } from './styles';

function CardReprovedItem({ image, reason, category, date }) {
  const productImage = image || getImageUrl(IMG_BAG_CARD);
  const dateFormatted = date
    ? moment(date).format('DD/MM/YYYY HH:mm')
    : 'Não Consta';
  return (
    <Card
      hoverable
      size="small"
      bodyStyle={{ margin: 0 }}
      cover={
        <Image
          alt={image ? 'Imagem do produto reprovado' : 'Sem dados'}
          style={{ objectFit: 'cover', height: '200px' }}
          src={productImage}
        />
      }
    >
      <CardContainer>
        <CardTitleDescription>Motivo</CardTitleDescription>
        <CardDescription>{reason}</CardDescription>
        <CardTitleDescription>Categoria</CardTitleDescription>
        <CardDescription>{category}</CardDescription>
        <CardTitleDescription>Data</CardTitleDescription>
        <CardDescription>{dateFormatted}</CardDescription>
      </CardContainer>
    </Card>
  );
}

CardReprovedItem.defaultProps = {
  image: null,
};

CardReprovedItem.propTypes = {
  image: PropTypes.string,
  category: PropTypes.string.isRequired,
  reason: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export default CardReprovedItem;
