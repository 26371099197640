import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import {
  FileSearchOutlined /* CrownOutlined, ShoppingOutlined */,
} from '@ant-design/icons';

import Button from '~/components/Button';

import {
  convertDate,
  toCurrencyFormat,
  formatPascalCase,
} from '~/util/Functions';
import { PARTICLES_OF_NAME } from '~/constants/Users';

export function generateColumns() {
  return [
    // TODO: IRÁ PARA VERSAO 2
    // {
    //   title: 'Premium',
    //   dataIndex: 'premium',
    //   key: 'premium',
    //   render: () => {
    //     return (
    //       <div>
    //         <CrownOutlined />
    //         <ShoppingOutlined  style={{ marginLeft: 10 }}/>    //
    //       </div>
    //     );
    //   },
    // },
    {
      title: 'Nome Completo',
      dataIndex: 'firstName',
      key: 'firstName',
      fixed: 'left',
      render: (_, record) => {
        return (
          <div style={{ width: '150px' }}>
            {formatPascalCase(
              `${record?.firstName ? record.firstName : ''} ${
                record?.lastName ? record.lastName : ''
              }`,
              PARTICLES_OF_NAME
            )}
          </div>
        );
      },
    },
    {
      title: 'CPF',
      dataIndex: 'document',
      key: 'document',
      render: (_, record) => {
        return (
          <div style={{ width: '120px' }}>
            {record.document ? record.document : 'N/A'}
          </div>
        );
      },
    },
    {
      title: 'E-Mail',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Telefone',
      dataIndex: 'mobilePhone',
      key: 'mobilePhone',
      render: (_, record) => {
        return <div style={{ width: '120px' }}>{record.mobilePhone}</div>;
      },
    },
    {
      title: 'Saldo Repassa',
      dataIndex: 'currentLastBalance',
      key: 'currentLastBalance',
      render: balance => toCurrencyFormat(balance),
    },
    {
      title: 'Nº de pedidos',
      dataIndex: 'ordersCount',
      key: 'ordersCount',
    },
    {
      title: 'Nº de Sacolas',
      dataIndex: 'bagsGeneratedCount',
      key: 'bagsGeneratedCount',
    },
    {
      title: 'Data de criação',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: text => convertDate(text),
    },
    // {
    //   title: 'Último login',
    //   dataIndex: 'dateLastLogin',
    //   key: 'dateLastLogin',
    //   render: date => convertDate(date),
    // }, task 5377 - comentar porque os dados estão mockados no backend
    {
      title: 'Ativo',
      dataIndex: 'active',
      key: 'active',
      render: (_, record) => <>{record.status === 'active' ? 'Sim' : 'Não'}</>,
    },
    {
      title: 'Ações',
      key: 'action',
      align: 'right',
      fixed: 'right',
      render: (_, record) => (
        <div style={{ width: '40px' }}>
          <Tooltip placement="bottom" title="Visualizar">
            <Link to={`/manage/service/client/edit/${record.id}`}>
              <Button
                data-testid="viewBtn"
                type="link"
                icon={<FileSearchOutlined />}
                size="small"
                style={{ margin: 0 }}
              />
            </Link>
          </Tooltip>
        </div>
      ),
    },
  ];
}
