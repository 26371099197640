import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  ArrowLeftOutlined,
  LinkOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import { Card, Row, Col, Descriptions, Typography, Alert, Tooltip } from 'antd';
import {
  // triggerErrorNotification,
  convertDate,
  getErrorMessage,
  toCurrencyFormat,
  formatPascalCase,
  renderShipping,
} from '~/util/Functions';
import {
  getPaymentStatus,
  getBagOrderStatus,
  getPaymentType,
  getShipmentStatus,
  getShipmentDispatchStatus,
} from '~/util/statusConverter';
import { PARTICLES_OF_NAME, USER_TAB_DATA } from '~/constants/Users';

import LoadingBar from '~/components/LoadingBar';
import useSearchBagOrder from '~/hooks/orders/useSearchBagOrder';
import useSellerCenterBagDetails from '~/hooks/orders/useSellerCenterBagDetails';
import {
  BAG_ORDER_TYPE_PURCHASE,
  ORDER_ORIGIN_CLIENT_BAG_LISTING,
  BAG_ORDER_TYPE_REPROVED,
} from '~/constants/BagOrder';
import { getBagOrderTypeLabel } from '~/util/BagOrder';
import {
  PAYMENT_TYPE_BANKSLIP,
  PAYMENT_TYPE_CREDIT_CARD,
  PAYMENT_TYPE_BALANCE,
  PAYMENT_TYPE_PIX,
  PAYMENT_TYPE_DONATION,
  STATUS_PAID,
} from '~/constants/Payment';
import Button from '~/components/Button';
import CardItem from './CardItem';
import ExpandableRawOrder from './ExpandableRawOrder';
import OrderStatusSteps from './OrderStatusSteps';

function OrderForm({ match }) {
  const { Text } = Typography;
  const { search: searchQueries } = useLocation();

  const searchParams = new URLSearchParams(searchQueries);
  const debugActive = searchParams.get('debug') === 'true';

  const returnLink = React.useMemo(() => {
    const fromValue = searchParams.get('from');
    const userId = searchParams.get('userId');

    if (fromValue === ORDER_ORIGIN_CLIENT_BAG_LISTING && userId) {
      return {
        tooltip: `Voltar para sacolas do usuário ${userId}`,
        link: `/manage/service/client/edit/${userId}?tab=bag`,
      };
    }

    return {
      tooltip: 'Voltar para a listagem dos pedidos de sacola',
      link: '/manage/orders/bag',
    };
  }, [searchQueries]);

  const orderId = useMemo(() => {
    return Number(match.params.id) || null;
  }, [match]);

  const [order, setOrder] = useState(null);

  const [searchBagOrder, { data, loading, error }] = useSearchBagOrder();
  const search = useCallback(searchBagOrder, [orderId]);

  const [
    fetchBagDetail,
    {
      data: sellerCenterBag,
      loading: sellerCenterLoading,
      error: sellerCenterError,
      called,
    },
  ] = useSellerCenterBagDetails();

  useEffect(() => {
    if (orderId) {
      search({
        bagOrderId: orderId,
        size: 1,
        skip: 0,
        full: true,
      });
    }
  }, [orderId, search]);

  useEffect(() => {
    if (
      data?.[0]?.kind === BAG_ORDER_TYPE_REPROVED &&
      data?.[0]?.bags?.[0]?.id &&
      !called
    ) {
      fetchBagDetail({ bagId: data[0].bags[0].id });
    }
  }, [data, fetchBagDetail]);

  useEffect(() => {
    if (data?.length) {
      let bagOrder = { ...data[0] };
      if (sellerCenterBag) {
        bagOrder = {
          ...bagOrder,
          bags: [
            {
              id: data[0].bags?.[0]?.id,
              qtyApprovedItems: sellerCenterBag?.detailBagDTO?.qtyApprovedItems,
              qtyReprovedItems:
                sellerCenterBag?.detailBagDTO?.qtyDisapprovedItems,
            },
          ],
        };
      }
      setOrder(bagOrder);
    }
  }, [sellerCenterBag, data]);

  const paymentDate = useMemo(() => {
    if (order) {
      return order?.paymentStatus === STATUS_PAID && order?.paymentDate
        ? order.paymentDate
        : null;
    }
    return null;
  }, [order]);

  const getPaymentTypeFormatted = paymentType => {
    const isBalanceOrPix =
      (paymentType === PAYMENT_TYPE_PIX ||
        paymentType === PAYMENT_TYPE_BALANCE) &&
      order?.paymentStatus === STATUS_PAID &&
      paymentDate;
    return isBalanceOrPix
      ? `Pago com ${getPaymentType(paymentType)} em ${convertDate(paymentDate)}`
      : getPaymentType(paymentType);
  };

  if (loading) return <LoadingBar />;

  const nameFormatted = formatPascalCase(
    `${order?.user?.firstName} ${order?.user?.lastName}`,
    PARTICLES_OF_NAME
  );

  const getPaymentTypeLabel = paymentType => {
    if (paymentType === PAYMENT_TYPE_DONATION)
      return order?.ongId ? 'Repasse Solidário 100%' : 'Não consta';
    return getPaymentTypeFormatted(paymentType) || 'Não consta';
  };

  return (
    <Card
      data-testid="orderCardForm"
      title={
        <>
          <Row gutter={16} type="flex" align="middle" justify="space-between">
            <Col md={12} xs={24}>
              <Tooltip
                placement="bottom"
                title={
                  returnLink?.tooltip || 'Voltar para a listagem dos pedidos'
                }
              >
                <Link to={returnLink?.link || '/manage/orders/bag'}>
                  <ArrowLeftOutlined data-testid="backToListBtn" />
                </Link>
              </Tooltip>
              <span data-testid="orderTitle" style={{ marginLeft: '10px' }}>
                PEDIDO {orderId || match.params.id} -{' '}
                {getBagOrderStatus(order?.status)}
              </span>
            </Col>
            <Col md={12} xs={24}>
              <Row type="flex" justify="end" align="middle">
                <Col>
                  <Row type="flex" justify="end" align="middle">
                    <Tooltip
                      placement="bottom"
                      title={
                        order?.trackingUrl
                          ? 'Abrir rastreio em uma nova aba.'
                          : 'Rastreio não está presente nesse pedido!'
                      }
                    >
                      <Button
                        data-testid="goToInvoiceBtn"
                        loading={loading}
                        type={order?.trackingUrl ? 'primary' : 'danger'}
                        disabled={!order?.trackingUrl}
                        icon={<LinkOutlined />}
                        href={order?.trackingUrl}
                        target="_blank"
                        style={{
                          padding: '0px 15px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        Rastreio do Pedido
                      </Button>
                    </Tooltip>
                    <Tooltip
                      placement="bottom"
                      title={
                        order?.invoiceUrl
                          ? 'Abrir fatura da Iugu em uma nova aba.'
                          : 'Fatura da Iugu não está presente nesse pedido!'
                      }
                    >
                      <Button
                        data-testid="goToInvoiceBtn"
                        loading={loading}
                        type={order?.invoiceUrl ? 'primary' : 'danger'}
                        disabled={!order?.invoiceUrl}
                        icon={<LinkOutlined />}
                        href={order?.invoiceUrl}
                        target="_blank"
                        style={{
                          padding: '0px 15px',
                          display: 'flex',
                          alignItems: 'center',
                          marginLeft: 10,
                        }}
                      >
                        Fatura Iugu
                      </Button>
                    </Tooltip>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      }
    >
      <Row gutter={[16, 16]}>
        {error && (
          <Col span={24}>
            <Alert message={getErrorMessage(error)} type="error" showIcon />
          </Col>
        )}
        {!order && (
          <Col span={24}>
            <Alert message="Pedido não encontrado!" type="warning" showIcon />
          </Col>
        )}
      </Row>
      {!error && order && (
        <>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <OrderStatusSteps
                bagOrderType={order?.kind}
                createdAt={order?.createdAt}
                bagOrderStatuses={order?.bagOrderStatuses}
                bagOrderPaymentStatus={order?.bagOrderPaymentStatus}
                bagOrderShipmentStatus={order?.shipmentStatuses}
                bagOrderDispatchStatus={order?.shipmentDispatch}
              />
            </Col>

            {debugActive ? (
              <Col span={24}>
                <Descriptions
                  layout="vertical"
                  bordered
                  column={{ xxl: 24, xl: 24, lg: 24, md: 24, sm: 24, xs: 1 }}
                >
                  <Descriptions.Item
                    label={
                      <span data-testid="orderDate">Status do Pedido</span>
                    }
                  >
                    {order?.status
                      ? getBagOrderStatus(order.status)
                      : 'Não consta'}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={
                      <span data-testid="orderDate">Status do Pagamento</span>
                    }
                  >
                    {order?.paymentStatus
                      ? getPaymentStatus(order.paymentStatus)
                      : 'Não consta'}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={<span data-testid="orderId">Status do Envio</span>}
                  >
                    {order?.shipmentStatus
                      ? getShipmentStatus(order.shipmentStatus)
                      : 'Não consta'}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            ) : null}

            <Col span={24} data-testid="orderDesc01">
              <Descriptions
                layout="vertical"
                bordered
                column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
              >
                <Descriptions.Item
                  label={<span data-testid="orderName">Nome</span>}
                >
                  {nameFormatted}
                  {order?.user?.id && (
                    <Tooltip
                      placement="bottom"
                      title="Ir para sacolas do cliente"
                    >
                      <Link
                        to={`/manage/service/client/edit/${order.user.id}?tab=${USER_TAB_DATA}`}
                      >
                        {' '}
                        <ExportOutlined />
                      </Link>
                    </Tooltip>
                  )}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<span data-testid="orderEmail">E-mail</span>}
                >
                  {order?.user?.email}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<span data-testid="orderAddress">Endereço</span>}
                >
                  {renderShipping(order?.shipping)}
                </Descriptions.Item>
              </Descriptions>
            </Col>

            <Col span={24}>
              <Descriptions
                layout="vertical"
                bordered
                column={{ xxl: 24, xl: 24, lg: 24, md: 24, sm: 24, xs: 1 }}
              >
                <Descriptions.Item
                  label={<span data-testid="orderDate">Data Pedido</span>}
                >
                  {convertDate(order?.createdAt)}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<span data-testid="orderId">Número do Pedido</span>}
                >
                  {orderId || match.params.id}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<span data-testid="orderType">Tipo do Pedido</span>}
                >
                  {getBagOrderTypeLabel(order?.kind) || 'Não consta'}
                </Descriptions.Item>
                {order?.kind !== BAG_ORDER_TYPE_PURCHASE && (
                  <Descriptions.Item
                    label={
                      <span data-testid="originalOrderId">
                        Número do Pedido Original
                      </span>
                    }
                  >
                    {order?.bagOrderOriginId ? (
                      <Tooltip
                        placement="bottom"
                        title="Abrir página do pedido original em uma nova aba."
                      >
                        <Link
                          to={`/manage/orders/bag/${order.bagOrderOriginId}`}
                          target="_blank"
                        >
                          <LinkOutlined /> {order.bagOrderOriginId}
                        </Link>
                      </Tooltip>
                    ) : (
                      'Não consta'
                    )}
                  </Descriptions.Item>
                )}
                <Descriptions.Item
                  label={
                    <span data-testid="orderPaymentType">
                      Forma de Pagamento
                    </span>
                  }
                >
                  {getPaymentTypeLabel(order?.paymentType)}
                </Descriptions.Item>
              </Descriptions>
            </Col>

            <Col span={24}>
              <Descriptions
                layout="vertical"
                bordered
                column={{ xxl: 24, xl: 24, lg: 24, md: 24, sm: 24, xs: 1 }}
              >
                <Descriptions.Item
                  label={<span data-testid="orderValue">Valor do Pedido</span>}
                >
                  {toCurrencyFormat(order?.total)}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <span data-testid="orderShipmentValue">Valor Frete</span>
                  }
                >
                  {toCurrencyFormat(order?.finalShipmentTotal)}
                </Descriptions.Item>

                <Descriptions.Item
                  label={
                    <span data-testid="orderBalanceValue">Saldo Repassa</span>
                  }
                >
                  {order?.paymentType === PAYMENT_TYPE_BALANCE && order?.total
                    ? toCurrencyFormat(order.total)
                    : 'Não consta'}
                </Descriptions.Item>

                <Descriptions.Item
                  label={
                    <span data-testid="orderDiscountCoupon">
                      Desconto Cupom
                    </span>
                  }
                >
                  {order?.adjustmentTotal
                    ? toCurrencyFormat(order?.adjustmentTotal)
                    : 'Não consta'}
                </Descriptions.Item>

                {(order.paymentType === PAYMENT_TYPE_CREDIT_CARD ||
                  order.paymentType === PAYMENT_TYPE_BANKSLIP) && (
                  <Descriptions.Item
                    label={
                      <span data-testid="orderPaymentDate">Data Pagamento</span>
                    }
                  >
                    {convertDate(paymentDate) || 'Não consta'}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Col>

            <Col span={24}>
              <Descriptions
                layout="vertical"
                bordered
                column={{ xxl: 24, xl: 24, lg: 24, md: 24, sm: 24, xs: 1 }}
              >
                <Descriptions.Item
                  label={
                    <span data-testid="orderDeliveryForm">
                      Status de Expedição
                    </span>
                  }
                >
                  {order?.shipmentDispatch?.status
                    ? getShipmentDispatchStatus(order.shipmentDispatch.status)
                    : 'Não consta'}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <span data-testid="orderDeliveryForm">Transportadora</span>
                  }
                >
                  {order?.shipmentCompany?.name || 'Não consta'}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <span data-testid="orderDeliveryForm">
                      Código de Rastreio
                    </span>
                  }
                >
                  {order?.tracking || 'Não consta'}
                  {order?.tracking && order?.trackingUrl && (
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      to={{ pathname: order.trackingUrl }}
                    >
                      {' '}
                      <ExportOutlined />
                    </Link>
                  )}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <span data-testid="orderDeliveryForm">
                      Código de Postagem
                    </span>
                  }
                >
                  {order?.reverseCode ? (
                    <>
                      {order.reverseCode}
                      {order?.reverseCodeExpiredAt && (
                        <Text type="secondary">
                          {' '}
                          (Expira em {convertDate(order.reverseCodeExpiredAt)})
                        </Text>
                      )}
                    </>
                  ) : (
                    'Não consta'
                  )}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <span data-testid="orderEstimatedDeliveryDateForm">
                      Estimativa de Entrega
                    </span>
                  }
                >
                  {order?.estimatedDeliveryDate
                    ? convertDate(order.estimatedDeliveryDate)
                    : 'Não consta'}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={24} />
          </Row>

          <Card
            data-testid="bags"
            title={<span style={{ marginLeft: 24 }}>SACOLAS</span>}
          >
            <Row gutter={[10, 5]}>
              {order?.bags?.map(bag => {
                return (
                  <Col
                    data-testid="bagCard"
                    xl={4}
                    lg={8}
                    md={8}
                    xs={24}
                    key={bag?.id}
                  >
                    <CardItem
                      bagId={bag?.id}
                      orderId={orderId}
                      approvedItems={bag?.qtyApprovedItems}
                      reprovedItems={bag?.qtyReprovedItems}
                      orderType={order?.kind}
                      loading={sellerCenterLoading}
                      error={sellerCenterError}
                    />
                  </Col>
                );
              })}
            </Row>
          </Card>
          {debugActive ? <ExpandableRawOrder order={order} /> : null}
        </>
      )}
    </Card>
  );
}

OrderForm.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape().isRequired,
  }).isRequired,
};

export default OrderForm;
