import React, { useEffect } from 'react';
import {
  Row,
  Col,
  Input,
  Typography,
  DatePicker,
  Select,
  notification,
} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DATE_FORMAT_SIMPLE } from '~/constants/Global';
import Button from '~/components/Button';
import {
  BAG_ORDER_STATUSES,
  BAG_ORDER_STATUS_CANCELED,
  BAG_ORDER_STATUS_COMPLETED,
  BAG_ORDER_STATUS_PENDING,
  BAG_ORDER_PAYMENT_TYPES,
  BAG_ORDER_PAYMENT_TYPE_CANCELED,
  BAG_ORDER_PAYMENT_TYPE_CREATED,
  BAG_ORDER_PAYMENT_TYPE_EXPIRED,
  BAG_ORDER_PAYMENT_TYPE_IN_ANALYSIS,
  BAG_ORDER_PAYMENT_TYPE_PAID,
  BAG_ORDER_PAYMENT_TYPE_REFUNDED,
  BAG_ORDER_PAYMENT_TYPE_REVERSED,
  BAG_ORDER_PAYMENT_TYPE_WAITING,
  BAG_ORDER_TYPES,
  BAG_ORDER_TYPE_PURCHASE,
  BAG_ORDER_TYPE_REPROVED,
  BAG_ORDER_TYPE_RETURN,
} from '~/constants/BagOrder';
import { PARTICLES_OF_NAME } from '~/constants/Users';
import {
  getPaymentStatus,
  getShipmentDispatchStatus,
  getBagOrderStatus,
  getShipmentStatus,
} from '~/util/statusConverter';
import { formatPascalCase } from '~/util/Functions';
import {
  SHIPMENT_STATUSES,
  SHIPMENT_WAITING_SENT,
  SHIPMENT_SEND,
  SHIPMENT_ON_ROUTE,
  SHIPMENT_DELIVERED,
  SHIPMENT_DELIVERY_NOT_MADE,
} from '~/constants/Shipment';
import {
  SHIPMENT_DISPATCH_AWAITING,
  SHIPMENT_DISPATCH_PROCESSED,
  SHIPMENT_DISPATCH_PROCESSING,
  SHIPMENT_DISPATCH_STATUS,
} from '~/constants/ShipmentDispatch';
import { getBagOrderTypeLabel } from '~/util/BagOrder';
import useShippingCompanies from '~/hooks/shippingCompanies/useShippingCompanies';

const { RangePicker } = DatePicker;
const { Option } = Select;

function Filter({ onChange, currentFilters, onSubmit, onReset }) {
  const { Text } = Typography;
  const {
    data: shippingCompaniesList,
    loading: loadingShippingCompanies,
    error: errorShippingCompanies,
  } = useShippingCompanies();

  useEffect(() => {
    if (!loadingShippingCompanies && errorShippingCompanies) {
      notification.error({
        description:
          'Não foi possível carregar a lista de transportadoras disponíveis.',
        duration: 4,
        placement: 'topRight',
      });
    }
  }, [errorShippingCompanies, loadingShippingCompanies]);

  const disabledDate = current => {
    // Não permite selecionar data maior que a data de hoje
    return current > moment();
  };

  const resetFields = () => {
    onReset();
  };

  const handleCalendarChange = value => {
    const firstDate = value?.[0];
    const secondDate = value?.[1];

    if ((firstDate && !secondDate) || (!firstDate && secondDate)) {
      notification.info({
        description: 'Selecione a data inicial e final',
        duration: 2,
        placement: 'topRight',
      });
    }

    if (firstDate && secondDate) {
      onChange({
        filters: {
          ...currentFilters,
          startDate: firstDate?.format(DATE_FORMAT_SIMPLE) || undefined,
          endDate: secondDate?.format(DATE_FORMAT_SIMPLE) || undefined,
        },
      });
    } else {
      onChange({
        filters: {
          ...currentFilters,
          startDate: undefined,
          endDate: undefined,
        },
      });
    }
  };

  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <>
      <Row gutter={[10, 10]} style={{ marginBottom: 15 }}>
        <Col xl={10} lg={24} md={24} xs={24}>
          <Input
            data-testid="inputOrderIdEmailUserName"
            placeholder="Buscar por número do pedido, e-mail ou nome do usuário"
            value={currentFilters?.searchText}
            onChange={e => {
              onChange({
                filters: {
                  ...currentFilters,
                  searchText: e.target.value,
                },
              });
            }}
          />
        </Col>

        <Col xl={5} lg={12} xs={24}>
          <Select
            data-testid="selectOrderType"
            style={{ width: '100%' }}
            placeholder="Tipo do pedido"
            value={currentFilters?.kind}
            onChange={value => {
              onChange({
                filters: {
                  ...currentFilters,
                  kind: value?.length ? value : undefined,
                },
              });
            }}
          >
            <Option value="" />
            <Option value={BAG_ORDER_TYPE_PURCHASE}>
              {getBagOrderTypeLabel(BAG_ORDER_TYPE_PURCHASE)}
            </Option>
            {/* TODO: Ainda não existe pedido de sacola do bem para reversa, será implementado futuramente */}
            <Option value={BAG_ORDER_TYPE_RETURN}>
              {getBagOrderTypeLabel(BAG_ORDER_TYPE_RETURN)}
            </Option>
            <Option value={BAG_ORDER_TYPE_REPROVED}>
              {getBagOrderTypeLabel(BAG_ORDER_TYPE_REPROVED)}
            </Option>
          </Select>
        </Col>

        {/* FILTRO DE PERÍODO */}
        <Col xl={9} lg={12} md={12} xs={24} data-testid="rangePicker">
          <RangePicker
            style={{ width: '100%' }}
            format="DD/MM/YYYY"
            inputReadOnly
            allowClear
            value={[
              currentFilters?.startDate
                ? moment(currentFilters.startDate)
                : undefined,
              currentFilters?.endDate
                ? moment(currentFilters.endDate)
                : undefined,
            ]}
            disabledDate={disabledDate}
            ranges={{
              Dia: [moment(), moment()],
              Semana: [moment().startOf('week'), moment()],
              Mês: [moment().startOf('month'), moment()],
            }}
            onCalendarChange={handleCalendarChange}
          />
        </Col>

        <Col xl={5} lg={12} md={12} xs={24}>
          <Select
            data-testid="selectBagOrderStatus"
            style={{ width: '100%' }}
            placeholder="Status do pedido"
            value={currentFilters?.status}
            onChange={value => {
              onChange({
                filters: {
                  ...currentFilters,
                  status: value?.length ? value : undefined,
                },
              });
            }}
          >
            <Option value="" />
            <Option value={BAG_ORDER_STATUS_PENDING}>
              {getBagOrderStatus(BAG_ORDER_STATUS_PENDING)}
            </Option>
            <Option value={BAG_ORDER_STATUS_COMPLETED}>
              {getBagOrderStatus(BAG_ORDER_STATUS_COMPLETED)}
            </Option>
            <Option value={BAG_ORDER_STATUS_CANCELED}>
              {getBagOrderStatus(BAG_ORDER_STATUS_CANCELED)}
            </Option>
          </Select>
        </Col>

        <Col xl={5} lg={12} md={12} xs={24}>
          <Select
            data-testid="selectPaymentStatus"
            style={{ width: '100%' }}
            placeholder="Status de pagamento"
            value={currentFilters?.paymentStatus}
            onChange={value => {
              onChange({
                filters: {
                  ...currentFilters,
                  paymentStatus: value?.length ? value : undefined,
                },
              });
            }}
          >
            <Option value="" />
            <Option value={BAG_ORDER_PAYMENT_TYPE_CREATED}>
              {getPaymentStatus(BAG_ORDER_PAYMENT_TYPE_CREATED)}
            </Option>
            <Option value={BAG_ORDER_PAYMENT_TYPE_WAITING}>
              {getPaymentStatus(BAG_ORDER_PAYMENT_TYPE_WAITING)}
            </Option>
            <Option value={BAG_ORDER_PAYMENT_TYPE_IN_ANALYSIS}>
              {getPaymentStatus(BAG_ORDER_PAYMENT_TYPE_IN_ANALYSIS)}
            </Option>
            <Option value={BAG_ORDER_PAYMENT_TYPE_CANCELED}>
              {getPaymentStatus(BAG_ORDER_PAYMENT_TYPE_CANCELED)}
            </Option>
            <Option value={BAG_ORDER_PAYMENT_TYPE_REFUNDED}>
              {getPaymentStatus(BAG_ORDER_PAYMENT_TYPE_REFUNDED)}
            </Option>
            <Option value={BAG_ORDER_PAYMENT_TYPE_REVERSED}>
              {getPaymentStatus(BAG_ORDER_PAYMENT_TYPE_REVERSED)}
            </Option>
            <Option value={BAG_ORDER_PAYMENT_TYPE_PAID}>
              {getPaymentStatus(BAG_ORDER_PAYMENT_TYPE_PAID)}
            </Option>
            <Option value={BAG_ORDER_PAYMENT_TYPE_EXPIRED}>
              {getPaymentStatus(BAG_ORDER_PAYMENT_TYPE_EXPIRED)}
            </Option>
          </Select>
        </Col>

        <Col xl={5} lg={12} md={12} xs={24}>
          <Select
            data-testid="selectShipmentStatus"
            style={{ width: '100%' }}
            placeholder="Status do envio"
            value={currentFilters?.shipmentStatus}
            onChange={value => {
              onChange({
                filters: {
                  ...currentFilters,
                  shipmentStatus: value?.length ? value : undefined,
                },
              });
            }}
          >
            <Option value="" />
            <Option value={SHIPMENT_WAITING_SENT}>
              {getShipmentStatus(SHIPMENT_WAITING_SENT)}
            </Option>
            <Option value={SHIPMENT_SEND}>
              {getShipmentStatus(SHIPMENT_SEND)}
            </Option>
            <Option value={SHIPMENT_ON_ROUTE}>
              {getShipmentStatus(SHIPMENT_ON_ROUTE)}
            </Option>
            <Option value={SHIPMENT_DELIVERED}>
              {getShipmentStatus(SHIPMENT_DELIVERED)}
            </Option>
            <Option value={SHIPMENT_DELIVERY_NOT_MADE}>
              {getShipmentStatus(SHIPMENT_DELIVERY_NOT_MADE)}
            </Option>
          </Select>
        </Col>

        <Col xl={9} lg={12} md={12} xs={24}>
          <Select
            data-testid="selectShippingCompanies"
            style={{ width: '100%' }}
            placeholder="Transportadora"
            value={currentFilters?.deliveryMethodId}
            onChange={value => {
              onChange({
                filters: {
                  ...currentFilters,
                  deliveryMethodId: value || undefined,
                },
              });
            }}
            disabled={errorShippingCompanies || !shippingCompaniesList?.length}
          >
            <Option value="" />
            {shippingCompaniesList?.map(company => (
              <Option key={company?.id} value={company?.id}>
                {formatPascalCase(company?.name, PARTICLES_OF_NAME)}{' '}
                <Text type="secondary" style={{ fontSize: '11px' }}>
                  (ID: {company?.id})
                </Text>
              </Option>
            ))}
          </Select>
        </Col>

        <Col xl={6} lg={12} md={12} xs={24}>
          <Select
            data-testid="selectShipmentDispatchStatus"
            style={{ width: '100%' }}
            placeholder="Status de expedição"
            value={currentFilters?.shipmentDispatchStatus}
            onChange={value => {
              onChange({
                filters: {
                  ...currentFilters,
                  shipmentDispatchStatus: value?.length ? value : undefined,
                },
              });
            }}
          >
            {/* TODO: Ainda não existe status de expedição no type de sacola do bem, será implementado futuramente */}
            {/* Remover "disabled" das options quando estiver pronto */}
            <Option value="" />
            <Option value={SHIPMENT_DISPATCH_AWAITING}>
              {getShipmentDispatchStatus(SHIPMENT_DISPATCH_AWAITING)}
            </Option>
            <Option value={SHIPMENT_DISPATCH_PROCESSING}>
              {getShipmentDispatchStatus(SHIPMENT_DISPATCH_PROCESSING)}
            </Option>
            <Option value={SHIPMENT_DISPATCH_PROCESSED}>
              {getShipmentDispatchStatus(SHIPMENT_DISPATCH_PROCESSED)}
            </Option>
          </Select>
        </Col>
      </Row>
      <Row gutter={[10, 10]} type="flex" align="middle" justify="space-between">
        <Col xl={8} lg={12} md={12} xs={24} style={{ display: 'flex' }}>
          <Button
            data-testid="buttonSubmit"
            type="success"
            spacing={false}
            style={{
              width: '100%',
              marginRight: 15,
            }}
            onClick={handleSubmit}
          >
            BUSCAR
          </Button>

          <Button
            data-testid="buttonClear"
            type="default"
            spacing={false}
            style={{ width: '100%' }}
            onClick={() => resetFields()}
          >
            LIMPAR BUSCA
          </Button>
        </Col>
        <Col xl={4} lg={12} md={12} xs={24}>
          <Button
            data-testid="goToInvoiceBtn"
            type="primary"
            href="/manage/orders/bag/dispatch"
            style={{
              padding: '0px 15px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            GERAR REMESSA
          </Button>
        </Col>
      </Row>
    </>
  );
}

Filter.defaultProps = {
  currentFilters: {
    searchText: undefined,
    status: undefined,
    paymentStatus: undefined,
    shipmentStatus: undefined,
    shipmentDispatchStatus: undefined,
    deliveryMethodId: undefined,
    startDate: undefined,
    endDate: undefined,
    kind: undefined,
  },
  onSubmit: () => {},
  onReset: () => {},
};

Filter.propTypes = {
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  currentFilters: PropTypes.shape({
    searchText: PropTypes.string,
    status: PropTypes.oneOf(BAG_ORDER_STATUSES),
    paymentStatus: PropTypes.oneOf(BAG_ORDER_PAYMENT_TYPES),
    shipmentStatus: PropTypes.oneOf(SHIPMENT_STATUSES),
    deliveryMethodId: PropTypes.number,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    kind: PropTypes.oneOf(BAG_ORDER_TYPES),
    shipmentDispatchStatus: PropTypes.oneOf(SHIPMENT_DISPATCH_STATUS),
  }),
};

export default Filter;
