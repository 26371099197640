import React, { useMemo, useState } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Typography,
  Divider,
  Select,
  Tooltip,
} from 'antd';
import {
  SaveOutlined,
  PlusOutlined,
  CopyOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Button from '~/components/Button';
import DDDSelector from '~/pages/service/user/components/DDDSelector';

import {
  getDDDandNumber,
  normalizeAssetsUri,
  notifyError,
  notifySuccess,
} from '~/util/Functions';
import {
  emailValidator,
  passwordValidator,
  validatorCompareToFirst,
  validatorCpf,
} from '~/util/Forms';

import { passwordRegex, phoneRegex, cpfRegex } from '~/constants/GeneralForms';
import {
  USER_GENDER_FEMALE,
  USER_GENDER_MALE,
  USER_GENDER_PREFER_NOT_TO_DISCLOSE,
  USER_GENDER_UNCERTAIN,
  USER_KIND_CUSTOMER,
  USER_KIND_ONG,
  USER_KIND_PARTNER,
  USER_TAB_DATA,
  userGenderLabels,
  userKindLabels,
} from '~/constants/Users';

import useCustomerSignUp from '~/hooks/user/useCustomerSignUp';
import useUpdateCustomer from '~/hooks/user/useUpdateCustomer';
import ImagesForm from './ImagesForm';

function BasicForm({ initialData, isEdit, userId, userKind }) {
  const [form] = Form.useForm();
  const { Title } = Typography;
  const { Option } = Select;
  const history = useHistory();

  const userLabel = userKindLabels[userKind];

  const gendersOptions = [
    { label: userGenderLabels[USER_GENDER_MALE], value: USER_GENDER_MALE },
    { label: userGenderLabels[USER_GENDER_FEMALE], value: USER_GENDER_FEMALE },
    {
      label: userGenderLabels[USER_GENDER_PREFER_NOT_TO_DISCLOSE],
      value: USER_GENDER_PREFER_NOT_TO_DISCLOSE,
    },
    {
      label: userGenderLabels[USER_GENDER_UNCERTAIN],
      value: USER_GENDER_UNCERTAIN,
    },
  ];

  const mobilePhone = useMemo(() => {
    return getDDDandNumber(initialData?.mobilePhone);
  }, [initialData.mobilePhone]);

  const [createCustomer, { loading: loadingCreate }] = useCustomerSignUp({
    onCompleted: data => {
      notifySuccess(
        `${userLabel} registrado`,
        `${userLabel} registrado com sucesso!`
      );
      const id = data?.userCustomerSignUp?.user?.id;
      history.replace(`/manage/service/client/edit/${id}?tab=${USER_TAB_DATA}`);
    },
    onError: err => {
      notifyError(`Erro ao registrar ${userLabel}`, err);
    },
  });

  const [updateCustomer, { loading: loadingUpdate }] = useUpdateCustomer({
    onCompleted: () => {
      notifySuccess(
        `${userLabel} atualizado`,
        `${userLabel} atualizado com sucesso`
      );
    },
    onError: err => {
      notifyError(`Erro ao atualizar ${userLabel}`, err);
    },
  });

  const onFinish = values => {
    if (!isEdit) {
      createCustomer({
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        password: values.password,
        passwordConfirmation: values.passwordConfirmation,
        gender: values.gender,
      });
    } else {
      updateCustomer({
        userId,
        document: values.document,
        firstName: values.firstName,
        lastName: values.lastName,
        ddd: values.ddd,
        mobilePhone: values.mobilePhone,
        gender: values.gender,
        password: values.password,
        passwordConfirmation: values.passwordConfirmation,
        avatar: values.avatar,
        banner: values.banner,
        slug: values.slug,
      });
    }
  };

  const onFinishFailed = () => {
    notifyError(`Erro ao criar ${userLabel}`);
  };

  const [copyText, setCopyText] = useState('Copiar slug');
  const copySlugToClipboard = () => {
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(initialData.slug);
      setCopyText('Copiado');
      setTimeout(() => setCopyText('Copiar slug'), 1000);
    }
  };

  const [copyEmail, setCopyEmail] = useState('Copiar email');
  const copyEmailToClipboard = () => {
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(initialData.email);
      setCopyEmail('Copiado');
      setTimeout(() => setCopyEmail('Copiar email'), 1000);
    }
  };

  return (
    <Form
      form={form}
      name="basic"
      data-testid="basicClientForm"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={{
        firstName: initialData.firstName,
        lastName: initialData.lastName,
        document: initialData.document,
        mobilePhone: mobilePhone.phone,
        ddd: mobilePhone.ddd,
        email: initialData.email,
        emailConfirmation: '',
        password: '',
        passwordConfirmation: '',
        gender: initialData.gender,
        slug: initialData.slug,
      }}
    >
      {isEdit && (
        <ImagesForm
          form={form}
          avatar={initialData?.avatar}
          banner={initialData?.banner}
        />
      )}
      <Row gutter={16} type="flex" align="top" justify="center">
        <Col span={24}>
          <Title level={4} style={{ margin: 0, fontWeight: 'normal' }}>
            Informações Básicas
          </Title>
          <Divider />
        </Col>
      </Row>
      <Row gutter={16} type="flex" align="top" justify="center">
        <Col xl={12} lg={24} md={24} xs={24}>
          <Form.Item
            label="Nome"
            name="firstName"
            rules={[
              {
                required: true,
                message: 'Digite o nome ',
                whitespace: true,
              },
            ]}
          >
            <Input data-testid="firstName" />
          </Form.Item>
        </Col>
        <Col xl={12} lg={24} md={24} xs={24}>
          <Form.Item
            label="Sobrenome"
            name="lastName"
            rules={[
              {
                required: true,
                message: 'Digite o sobrenome ',
                whitespace: true,
              },
            ]}
          >
            <Input data-testid="lastName" />
          </Form.Item>
        </Col>
      </Row>

      {isEdit && (
        <Row gutter={16} type="flex" align="top" justify="center">
          <Col xl={12} lg={24} md={24} xs={24}>
            <Form.Item
              label="CPF"
              name="document"
              rules={[
                {
                  required: true,
                  message: 'Digite o CPF. ',
                  whitespace: true,
                  pattern: cpfRegex,
                },
                {
                  validator: validatorCpf(form, {
                    errorMessage: 'Informe um CPF válido',
                  }),
                },
              ]}
            >
              <Input data-testid="document" />
            </Form.Item>
          </Col>
          <Col xl={12} lg={24} md={24} xs={24}>
            <Form.Item
              label="DDD + Celular"
              name="mobilePhone"
              tooltip="Quando o número estiver vazio o DDD será desconsiderado"
              rules={[
                {
                  message: 'Digite um número de celular válido. ',
                  whitespace: true,
                  pattern: phoneRegex,
                },
              ]}
            >
              <Input addonBefore={<DDDSelector />} data-testid="mobilePhone" />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={16} type="flex" align="top" justify="center">
        <Col
          xl={isEdit ? 24 : 12}
          lg={isEdit ? 24 : 12}
          md={isEdit ? 24 : 12}
          xs={24}
        >
          <Form.Item
            label="E-Mail"
            name="email"
            hasFeedback
            rules={[
              {
                type: 'email',
                required: !isEdit,
                message: 'Digite um e-mail válido. ',
                whitespace: true,
              },
              {
                validator: (rule, value) =>
                  emailValidator(rule, value, form, 'emailConfirmation'),
              },
            ]}
          >
            <Input
              data-testid="email"
              disabled={isEdit}
              addonAfter={
                isEdit ? (
                  <Tooltip title={copyEmail}>
                    <Button
                      type="text"
                      size="small"
                      icon={<CopyOutlined />}
                      onClick={copyEmailToClipboard}
                    />
                  </Tooltip>
                ) : null
              }
            />
          </Form.Item>
        </Col>
        {!isEdit && (
          <Col xl={12} lg={12} md={12} xs={24}>
            <Form.Item
              label="Confirmação de E-Mail"
              name="emailConfirmation"
              hasFeedback
              rules={[
                {
                  type: 'email',
                  required: !isEdit,
                  message: 'Confirme com um e-mail válido. ',
                  whitespace: true,
                },
                {
                  validator: validatorCompareToFirst(form, {
                    field: 'email',
                    errorMessage: 'Os e-mails são diferentes',
                  }),
                },
              ]}
            >
              <Input data-testid="emailConfirmation" disabled={isEdit} />
            </Form.Item>
          </Col>
        )}
      </Row>

      {!isEdit && (
        <Row gutter={16} type="flex" align="top" justify="center">
          <Col xl={12} lg={12} md={12} xs={24}>
            <Form.Item
              label="Senha"
              name="password"
              hasFeedback
              rules={[
                {
                  required: !isEdit,
                  validator: (rule, value) =>
                    passwordValidator(
                      rule,
                      value,
                      form,
                      'passwordConfirmation'
                    ),
                },
              ]}
            >
              <Input.Password data-testid="password" />
            </Form.Item>
          </Col>

          <Col xl={12} lg={12} md={12} xs={24}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.password !== currentValues.password
              }
            >
              {({ getFieldValue }) => (
                <Form.Item
                  label="Confirmação senha"
                  name="passwordConfirmation"
                  hasFeedback
                  rules={[
                    {
                      required: !isEdit || getFieldValue('password').length > 0,
                      message: 'Confirme sua senha. ',
                      pattern: passwordRegex,
                    },
                    {
                      validator: validatorCompareToFirst(form, {
                        field: 'password',
                        errorMessage: 'Senha e confirmação não são iguais.',
                      }),
                    },
                  ]}
                >
                  <Input.Password
                    data-testid="passwordConfirmation"
                    disabled={getFieldValue('password').length === 0}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row gutter={24} type="flex" align="top" justify="start">
        <Col xl={12} lg={12} md={12} xs={24}>
          <Form.Item
            label="Gênero"
            name="gender"
            rules={[
              {
                required: true,
                message: 'Selecione um gênero',
              },
            ]}
          >
            <Select data-testid="gender">
              {gendersOptions.map(({ label, value }) => (
                <Option key={value} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {isEdit && (
          <Col xl={12} lg={12} md={12} xs={24}>
            <Form.Item
              label="Username para URL do perfil"
              name="slug"
              rules={[
                {
                  message: 'Digite o slug',
                  whitespace: true,
                },
              ]}
            >
              <Input
                data-testid="slug"
                disabled
                addonAfter={
                  <>
                    <Tooltip title={copyText}>
                      <Button
                        type="text"
                        size="small"
                        icon={<CopyOutlined />}
                        onClick={copySlugToClipboard}
                      />
                    </Tooltip>
                    <Tooltip title="Abrir vitrine">
                      <Button
                        target="_blank"
                        href={`${normalizeAssetsUri(
                          process.env.REACT_APP_STOREFRONT_URL
                        )}vitrines/${initialData.slug}`}
                        type="text"
                        size="small"
                        icon={<LinkOutlined />}
                      />
                    </Tooltip>
                  </>
                }
              />
            </Form.Item>
          </Col>
        )}
      </Row>

      <Row
        gutter={16}
        type="flex"
        align="top"
        justify="end"
        style={{ marginTop: '10px' }}
      >
        <Col>
          <Form.Item>
            <Button
              data-testid="basicSubmitBtn"
              type="success"
              htmlType="submit"
              icon={isEdit ? <SaveOutlined /> : <PlusOutlined />}
              block
              style={{ margin: 0 }}
              loading={loadingCreate || loadingUpdate}
            >
              {isEdit ? 'Salvar' : 'Adicionar'}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

BasicForm.defaultProps = {
  userId: null,
  initialData: {
    firstName: '',
    lastName: '',
    document: '',
    mobilePhone: '',
    email: '',
    gender: '',
    slug: '',
    avatar: null,
    banner: null,
  },
};

BasicForm.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  userId: PropTypes.number,
  userKind: PropTypes.oneOf([
    USER_KIND_CUSTOMER,
    USER_KIND_ONG,
    USER_KIND_PARTNER,
  ]).isRequired,
  initialData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    document: PropTypes.string,
    mobilePhone: PropTypes.string,
    email: PropTypes.string,
    gender: PropTypes.string,
    slug: PropTypes.string,
    avatar: PropTypes.string,
    banner: PropTypes.string,
  }),
};

export default BasicForm;
