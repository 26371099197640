/* eslint-disable no-unused-expressions */
import { useState } from 'react';
import getToken from '~/util/getToken';

function useSellerCenterBagDetails(
  options = {
    onCompleted: () => {},
    onError: () => {},
  }
) {
  const accessToken = getToken();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [called, setCalled] = useState(null);

  const fetchBag = ({ bagId = null }) => {
    if (bagId) {
      setLoading(true);
      setCalled(true);
      fetch(`${process.env.REACT_APP_BFF_URI}?bagId=${bagId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: accessToken,
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Error on request Bag Details Seller Center');
          }

          return response.json();
        })
        .then(response => {
          if (response?.err) {
            setData(null);
            setError(response);
            options?.onError?.(response);
          } else {
            setData(response?.data);
            setError(null);
            options?.onCompleted?.(response);
          }
        })
        .catch(err => {
          setData(null);
          setError(err);
          options?.onError?.(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return [
    fetchBag,
    {
      loading,
      data,
      error,
      called,
    },
  ];
}

export default useSellerCenterBagDetails;
