import React from 'react';
import PropTypes from 'prop-types';
import { Card, Tooltip, Spin, Alert, Typography } from 'antd';

import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { getImageUrl } from '~/util/Functions';

import { IMG_BAG_CARD } from '~/constants/Images';
import { BAG_ORDER_TYPE_REPROVED } from '~/constants/BagOrder';
import Image from '~/components/Image';
import { CardContainer, CardID, CardDescription } from './styles';

function CardItem({
  orderId,
  bagId,
  approvedItems,
  reprovedItems,
  orderType,
  loading = false,
  error = false,
}) {
  const { Text } = Typography;
  const isReprovedOrder = orderType === BAG_ORDER_TYPE_REPROVED;
  const isLoading = isReprovedOrder && loading;
  const isError = isReprovedOrder && error;
  return (
    <Card
      hoverable
      size="small"
      bodyStyle={{ margin: 0 }}
      cover={
        <Image
          alt="Sem dados"
          style={{ objectFit: 'cover', height: '200px' }}
          src={getImageUrl(IMG_BAG_CARD)}
        />
      }
    >
      <CardContainer>
        <CardID>ID: {bagId}</CardID>
        {isLoading && (
          <Spin indicator={<LoadingOutlined spin />} size="small" />
        )}
        {isError && (
          <Alert
            message={
              <Text style={{ fontSize: '11px' }}>
                Não foi possível obter os dados do Seller Center
              </Text>
            }
            type="error"
          />
        )}
        {isReprovedOrder && !isLoading && !isError && (
          <>
            <CardDescription>Peças aprovadas: {approvedItems}</CardDescription>
            {reprovedItems ? (
              <CardDescription>
                <Tooltip
                  placement="bottom"
                  title={`Abrir página com os detalhes ${
                    reprovedItems > 1
                      ? 'das peças reprovadas'
                      : 'da peça reprovada'
                  }`}
                >
                  <Link
                    to={`/manage/orders/bag/reproved/${bagId}?order=${orderId}`}
                  >
                    Peças reprovadas: {reprovedItems}
                  </Link>
                </Tooltip>
              </CardDescription>
            ) : (
              <CardDescription>
                Peças reprovadas: {reprovedItems}
              </CardDescription>
            )}
          </>
        )}
      </CardContainer>
    </Card>
  );
}

CardItem.defaultProps = {
  approvedItems: null,
  reprovedItems: null,
  loading: false,
  error: false,
};

CardItem.propTypes = {
  orderId: PropTypes.number.isRequired,
  bagId: PropTypes.number.isRequired,
  approvedItems: PropTypes.number,
  reprovedItems: PropTypes.number,
  orderType: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

export default CardItem;
