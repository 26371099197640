import React, { useState, useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Card, Row, Col, Descriptions, Alert, Tooltip } from 'antd';
import moment from 'moment';
import { getBagReprovedDestinyLabel } from '~/util/BagOrder';
import LoadingBar from '~/components/LoadingBar';
import useSellerCenterBagDetails from '~/hooks/orders/useSellerCenterBagDetails';
import CardReprovedItem from './CardReprovedItem';

function ReprovedItems({ match }) {
  const { search: searchQueries } = useLocation();
  const searchParams = new URLSearchParams(searchQueries);
  const [bagReproved, setBagReproved] = useState(null);

  const orderId = useMemo(() => {
    return Number(searchParams.get('order')) || null;
  }, [searchParams]);

  const bagId = useMemo(() => {
    return Number(match.params.id) || null;
  }, [match]);

  const [fetchBagDetail, { data, error, loading }] =
    useSellerCenterBagDetails();

  useEffect(() => {
    if (bagId) fetchBagDetail({ bagId });
  }, [bagId]);

  useEffect(() => {
    if (data) setBagReproved(data);
  }, [data]);

  if (loading) return <LoadingBar />;

  const disapprovedDates = bagReproved?.detailBagDTO?.disapprovedItemDTO
    ?.map(date => date?.disapprovedDate)
    .filter(date => date && new Date(date.disapprovedDate));
  const oldestDisapprovedDate = disapprovedDates?.sort(
    (a, b) => new Date(a) - new Date(b)
  )[0];
  const dateFormatted = oldestDisapprovedDate
    ? moment(oldestDisapprovedDate).format('DD/MM/YYYY HH:mm')
    : 'Não Consta';

  return (
    <Card
      data-testid="bagReprovedItemsForm"
      title={
        <Row gutter={16} type="flex" align="middle" justify="space-between">
          <Col>
            <Tooltip
              placement="bottom"
              title="Voltar para a página do pedido da Sacola"
            >
              <Link
                to={
                  orderId
                    ? `/manage/orders/bag/${orderId}`
                    : '/manage/orders/bag'
                }
              >
                <ArrowLeftOutlined data-testid="backToBagOrderBtn" />
              </Link>
            </Tooltip>
            <span data-testid="bagTitle" style={{ marginLeft: '10px' }}>
              Sacola {bagId || match.params.id}
            </span>
          </Col>
        </Row>
      }
    >
      {error && (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Alert
              message="Não foi possível obter os dados do Seller Center. Tente novamente mais tarde."
              type="error"
              showIcon
            />
          </Col>
        </Row>
      )}
      {bagReproved && (
        <>
          <Row gutter={[16, 16]}>
            <Col span={24} data-testid="bagReprovedDetail01">
              <Descriptions
                layout="vertical"
                bordered
                column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
              >
                <Descriptions.Item
                  label={<span data-testid="bagCode">Código da Sacola</span>}
                >
                  {bagReproved?.bagId || 'Não Consta'}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<span data-testid="bagDate">Data</span>}
                >
                  {dateFormatted}
                </Descriptions.Item>
              </Descriptions>
            </Col>

            <Col span={24} data-testid="bagReprovedDetail02">
              <Descriptions
                layout="vertical"
                bordered
                column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
              >
                <Descriptions.Item
                  label={
                    <span data-testid="bagApprovedItemsQuantity">
                      Aprovadas
                    </span>
                  }
                >
                  {bagReproved?.qtyApprovedItems || 'Não Consta'}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <span data-testid="bagDisapprovedItemsQuantity">
                      Reprovadas
                    </span>
                  }
                >
                  {bagReproved?.qtyReprovedItems || 'Não Consta'}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <span data-testid="bagReprovedItemsDestiny">
                      Destino Escolhido
                    </span>
                  }
                >
                  {bagReproved?.detailBagDTO?.destinyStatus
                    ? getBagReprovedDestinyLabel(
                        bagReproved.detailBagDTO.destinyStatus
                      )
                    : 'Não Consta'}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={24} />
          </Row>
          <Card
            data-testid="reprovedItems"
            title={<span style={{ marginLeft: 24 }}>Peças Reprovadas</span>}
          >
            <Row gutter={[10, 5]}>
              {bagReproved?.detailBagDTO?.disapprovedItemDTO?.map(item => {
                return (
                  <Col
                    data-testid="reprovedItemsCard"
                    xl={6}
                    lg={8}
                    md={8}
                    xs={24}
                    key={item?.id}
                  >
                    <CardReprovedItem
                      image={item?.photoLink}
                      reason={item?.reasonName}
                      category={item?.categoryName}
                      date={item?.disapprovedDate}
                    />
                  </Col>
                );
              })}
            </Row>
          </Card>
        </>
      )}
    </Card>
  );
}

ReprovedItems.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape().isRequired,
  }).isRequired,
};

export default ReprovedItems;
