import React from 'react';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { notificationInfoWithTimeBar } from '~/util/notification';

export const FRAGMENT_BASIC_BAG_ORDER = gql`
  fragment BasicBagOrderFragment on BagOrder {
    id
    createdAt
    updatedAt
    quantity
    barcode
    status: currentStatus
    kind
    total
    userId
    paymentStatus
    paymentType
    shipmentStatus
    shipmentCompany {
      name
    }
    shipmentDispatch {
      status
    }
    shipmentCompany {
      name
    }
    user {
      id
      email
      firstName
      lastName
    }
  }
`;

export const FRAGMENT_FULL_BAG_ORDER = gql`
  fragment FullBagOrderFragment on BagOrder {
    adjustmentTotal
    bankSlipCode
    bagOrderStatuses
    bagOrderPaymentStatus
    bagOrderOriginId
    finalShipmentTotal
    invoiceUrl
    paymentDate
    tracking
    trackingUrl
    trackingStatus
    reverseCode
    reverseCodeExpiredAt
    estimatedDeliveryDate
    bags {
      id
      qtyApprovedItems
      qtyReprovedItems
    }
    shipping {
      address1
      address2
      number
      city
      state {
        name
      }
    }
    paymentStatus
    shipmentStatuses
    shipmentDispatch {
      id
      status
      updatedAt
    }
    ongId
  }
`;

export const QUERY_SEARCH_BAG_ORDERS = gql`
  query useSearchBagOrder(
    $skip: Int
    $first: Int
    $status: BagOrderStatus
    $kind: BagOrderKind
    $paymentStatus: BagOrderPaymentStatus
    $shipmentStatus: BagShipmentStatus
    $emailName: String
    $dateCreateBagOrder: [String!]
    $shipmentDispatchStatus: BagOrderShipmentDispatch
    $bagOrderId: Int
    $deliveryMethodId: Int
    $full: Boolean = false
  ) {
    bagsBagAdminListBagOrders(
      skip: $skip
      first: $first
      status: $status
      kind: $kind
      paymentStatus: $paymentStatus
      shipmentStatus: $shipmentStatus
      emailName: $emailName
      dateCreateBagOrder: $dateCreateBagOrder
      shipmentDispatchStatus: $shipmentDispatchStatus
      bagOrderId: $bagOrderId
      deliveryMethodId: $deliveryMethodId
    ) {
      nodes {
        ...BasicBagOrderFragment
        ...FullBagOrderFragment @include(if: $full)
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
  ${FRAGMENT_BASIC_BAG_ORDER}
  ${FRAGMENT_FULL_BAG_ORDER}
`;

function useSearchBagOrder(options = {}) {
  const [searchBagOrder, { error, data, loading, refetch }] = useLazyQuery(
    QUERY_SEARCH_BAG_ORDERS,
    {
      fetchPolicy: 'no-cache',
      ...options,
    }
  );

  return [
    ({
      skip = 0,
      first = 10,
      status = undefined,
      kind = undefined,
      paymentStatus = undefined,
      shipmentStatus = undefined,
      emailNameIdBagOrder = undefined,
      bagOrderId = undefined,
      dateCreateBagOrder = undefined,
      shipmentDispatchStatus = undefined,
      deliveryMethodId = undefined,
      full = false,
    }) => {
      const id = Number(emailNameIdBagOrder) || bagOrderId;

      if (id && dateCreateBagOrder) {
        notificationInfoWithTimeBar({
          key: 'searchWarning',
          message: 'Aviso',
          description: (
            <span>
              Sua busca possui um <b>ID</b> e um <b>intervalo de data</b>. O
              intervalo de data será <b>desconsiderado</b>. Não há garantia de
              que o ID informado está dentro do intervalo de data selecionado.
            </span>
          ),
          duration: 10,
          placement: 'topRight',
        });
      }

      searchBagOrder({
        variables: {
          skip,
          first,
          status,
          kind,
          paymentStatus,
          shipmentStatus,
          full,
          shipmentDispatchStatus,
          // Caso a busca seja por ID, desconsidera a data, uma vez que o resultado vai trazer um pedido de sacola exato
          emailName: id ? undefined : emailNameIdBagOrder,
          dateCreateBagOrder: id ? undefined : dateCreateBagOrder,
          bagOrderId: id,
          deliveryMethodId,
        },
      });
    },
    {
      data: data?.bagsBagAdminListBagOrders?.nodes || [],
      totalCount: data?.bagsBagAdminListBagOrders?.totalCount || 0,
      loading,
      error,
      refetch,
    },
  ];
}

export default useSearchBagOrder;
