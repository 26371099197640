import AwsS3 from '@uppy/aws-s3';
import Uppy from '@uppy/core';
import { useState } from 'react';
import getToken from '~/util/getToken';

const parseFile = file => {
  return {
    name: file.name,
    type: file.type,
    data: file,
    source: 'Local',
    isRemote: false,
  };
};

const addFileToUppy = (uppy, file) => {
  if (Array.isArray(file))
    file.forEach(item => uppy.addFile(parseFile(item.file)));
  else uppy.addFile(parseFile(file));
};

const setupAws = async uppy => {
  await uppy.use(AwsS3, {
    async getUploadParameters(file) {
      const url = `${process.env.REACT_APP_NEW_BACKEND_UPLOAD_URI}?filename=${file.name}&type=${file.type}`;
      const token = getToken();

      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: token,
        },
        params: {
          filename: file.name,
          type: file.type,
        },
      };

      const response = await fetch(url, options);
      const data = await response.json();
      return data;
    },
  });
};

const uploadFile = (uppy, setError) => {
  uppy.upload().then(result => {
    if (result.failed.length > 0) setError(result.failed);
  });
};

const UppyUpload = id => {
  const [error, setError] = useState([]);
  const [image, setImage] = useState(null);
  const uppy = new Uppy({ allowMultipleUploads: true, id });

  const upload = async file => {
    setError([]);
    addFileToUppy(uppy, file);
    await setupAws(uppy);
    uploadFile(uppy, setError);

    await uppy.on('upload-success', uploadedFile => {
      setImage(
        JSON.stringify({
          id: uploadedFile.meta.key.match(/^cache\/(.+)/)[1],
          storage: 'cache',
          metadata: {
            size: uploadedFile.size,
            filename: uploadedFile.name,
            mime_type: uploadedFile.type,
          },
        })
      );
    });
  };

  const clearErrors = () => setError([]);

  return [image, upload, error, { clearErrors }];
};

export default UppyUpload;
